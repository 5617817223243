.login__page {
  padding: 100px 0 50px;

  & form {
    position: relative;
    // border-radius: 10px;
    background: linear-gradient(to bottom right, #474444, #181819);
    width: 900px;
    display: flex;
    margin: 0 auto;

    & .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
      color: rgba(0, 0, 0, 0.6) !important;
      font-size: 1rem !important;
      text-transform: none;
      font-weight: 400 !important;
    }

    & .MuiGrid-grid-xs-7 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 70px;

      & .login--header {
        text-align: center;
        margin-bottom: 15px;

        & p {
          text-align: center;
          color: #fff;
          font-size: 13px;
        }
      }

      & .css-sghohy-MuiButtonBase-root-MuiButton-root {
        padding: 13px 80px;
        background-color: #3ab19b;
        // border-radius: 30px;
        margin-top: 20px;
      }

      & .MuiOutlinedInput-root {
        color: #474444;
        background: linear-gradient(to bottom right, #fff, #f7f5c7);
      }
    }

    & .MuiTypography-h1 {
      font-size: 50px;
      font-weight: 600;
      color: #ebe784;
    }

    & .logo {
      margin: auto;
      // position: absolute;
      // top: 15px;
      // left: 15px;
      // width: 80px;
    }

    & .login--social__media {
      text-align: center;
      margin: 10px 0;

      & svg {
        color: #000;
      }
    }

    & .forgot--pass {
      text-decoration: underline;
      margin-bottom: 10px;

      & a {
        color: #fff;
      }
    }

    & .MuiGrid-grid-xs-5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0px 45px;
      background-color: #ebe784;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      & h2 {
        font-size: 45px;
        font-weight: 500;
        color: #474444;
      }

      & p {
        color: #474444;
        padding: 20px 40px;
        font-size: 15px;
      }

      & button {
        padding: 13px 80px;
        background-color: #ebe784;
        border-radius: 30px;
        border: 2px solid #474444;
        box-shadow: none;
        color: #474444;

        & a {
          color: #fff;
        }
      }
    }
  }

  & .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    margin-bottom: 15px;
  }
}

.container {
  background-color: transparent;
  border-radius: 10px;
  // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  /*  margin: auto; */
  top: 25%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: transparent linear-gradient(180deg, #313131 0%, #202020 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #313131 0%, #202020 100%) 0%
    0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;

  & .MuiTypography-h2 {
    color: #fff;
    margin-bottom: 10px;
  }

  & .MuiTypography-body1 {
    color: #fff;
    margin-bottom: 20px;
  }

  & .MuiTypography-caption {
    color: #fff;
    margin-bottom: 20px;
  }
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.forgot_password_modal {
  background: linear-gradient(to bottom right, #474444, #181819);
  padding: 30px;
  position: absolute;
  top: 40%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .MuiGrid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & h5 {
    text-align: center;
    margin-bottom: 15px;
    color: #ebe784;
  }

  & h6 {
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    border-style: solid;
  }

  & .MuiOutlinedInput-root {
    color: #474444;
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
    width: 100%;
    margin-bottom: 20px;
  }

  & .MuiFormLabel-root {
    text-align: left;
    align-self: left;
    width: 100%;
    margin-left: 5px;
    color: #ebe784;
  }
}
