.admin-container {
  background-color: #1b1b1b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  & .admin-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 50px 30px 0px;

    & .tab {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    & .add-button {
      margin: 20px;
    }

    & .MuiTab-root {
      color: #fff;
    }

    & .Mui-selected {
      color: #ebe784 !important;
    }
  }

  & .admin-overview {
    width: 75%;
    margin: 20px;

    & .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
      color: #ebe784;
      font-weight: 700;
      font-size: 18px;
      background-color: #242222;
    }

    & .MuiTableContainer-root {
      background-color: #242222;
      border-color: #242222;

      & .MuiTableHead-root {
        & .MuiTableCell-root {
          border-color: #b1b1b1;
        }
      }

      & .MuiTableCell-root {
        border-color: #242222;
      }
    }

    & .MuiTableCell-root {
      color: #fff;

      & .MuiTypography-body1 {
        color: #fff;
      }
    }

    & .goals_list {
      width: 100%;
      background-color: transparent;
      margin: 10px;

      & .edit_container {
        display: flex;
      }

      & .expand_icon {
        color: #ebe784;
      }
    }

    & .MuiDivider-root {
      border-color: #fff;
    }

    & .goal_item {
      width: 100%;

      & .MuiListItemText-root {
        width: 25%;
      }

      & .goal_value {
        font-weight: 700;
        font-style: italic;
      }
    }

    & .team_goals {
      min-height: 20vh;
      margin-bottom: 20px;
    }

    & .individual_goals {
      min-height: 20vh;

      & .avatar_list {
        margin-top: 20px;
      }

      & .avatar_container {
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        flex-direction: column;
        text-align: center;
        height: 100px;
        transition: transform 0.2s;

        & .avatar {
          margin-bottom: 2px;
        }

        & .active_avatar {
          border-style: solid;
          border-color: #ebe784;
        }
      }

      & .avatar_container_selected {
        transform: scale(1.4) !important;
      }

      & .avatar_container:hover {
        transform: scale(1.2);
      }
    }

    & .status-text {
      color: #ebe784 !important;
      font-size: 14px;
      font-style: italic;
    }

    & h4 {
      color: #ebe784 !important;
      font-size: 40px;
      font-weight: 600;
    }

    & .MuiTypography-root,
    p {
      color: #fff;
    }

    & .button {
      margin: 20px;
    }

    & .icon-button {
      font-size: 20px;
    }

    & .placeholder-text {
      font-style: italic;
      font-size: 14px;
      opacity: 0.8;
    }

    & .table-head {
      background-color: transparent !important;
      border-style: solid;
    }

    & .row-name {
      color: #fff;
      font-size: 16px;
    }

    & .table-cell-value {
      color: #ebe784;
    }

    & .MuiIconButton-root {
      margin-left: 10px;
    }

    & .table-cell-goal {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  & .MyCompetitions {
    & .MuiTypography-root,
    p {
      color: #000;
    }
  }

  & .error-text {
    margin-left: 10px;
  }

  & .layout-grid-builder {
    position: relative;
    padding-top: 100px;

    & .MuiGrid-item {
      position: relative;
      border-style: solid;
      border-color: #ffe784;

      & .widget-grid {
        min-height: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & .MuiTypography-root {
          font-weight: 700;
          position: relative;
        }
      }

      & .widget-grid-selected {
        position: relative;
        background-color: #ebe784;
        opacity: 0.1;
      }
      & .widget-grid-selectable:hover {
        position: relative;
        background-color: #ebe784;
        opacity: 0.1;
      }
    }
  }

  & .layout-grid-builder::before {
    content: '';
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }

  & .my-team-container {
    width: 75%;
    margin: 20px;

    & .MuiPaginationItem-root {
      color: #fff;
    }

    & .MuiTypography-subtitle1 {
      color: graytext;
    }

    & .register-link-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & .register-link {
      color: #fff !important;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      margin-right: 10px;
    }

    & .status-text {
      color: #ebe784 !important;
      font-size: 14px;
      font-style: italic;
    }

    & h4 {
      color: #ebe784 !important;
      font-size: 40px;
      font-weight: 600;
    }

    & .member-body {
      width: 100%;
      & .member-container {
        display: flex;
        background-color: #52565a;
        border-bottom-right-radius: 15px;
        width: 100%;
      }

      & .member-profile {
        padding: 8px 23px 8px 11px;
        background-color: #66707b;
        clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);

        & span {
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px;
          border-radius: 50%;
        }
      }

      & .member-div {
        width: 100%;
        display: flex;
        flex-direction: column;

        padding: 10px 15px;
        & .member-info {
          /*  display: flex;
          align-items: center;
          justify-content: space-between; */
          margin-bottom: 5px;

          & .MuiTableCell-root {
            height: 10px;
          }

          & .MuiTableHead-root {
            & .MuiTableCell-root {
              color: #ffe784;
              text-align: center;
            }
          }

          & .MuiTableBody-root {
            & .MuiTableCell-root {
              color: #fff;
              text-align: center;
            }
          }

          & h6 {
            color: #fff;
            font-size: 16px;
            line-height: normal;

            &:last-of-type {
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  & .MuiOutlinedInput-root {
    color: #474444;
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
  }
}

.add_goal_modal {
  background: linear-gradient(to bottom right, #474444, #181819);
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .MuiGrid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & h5 {
    text-align: center;
    margin-bottom: 15px;
    color: #ebe784;
  }

  & h6 {
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    border-style: solid;
  }

  & .MuiOutlinedInput-root {
    color: #474444;
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
    width: 100%;
  }

  & .MuiFormLabel-root {
    text-align: left;
    align-self: left;
    width: 100%;
    margin-left: 5px;
    color: #ebe784;
  }
}

.addCompetition {
  color: #fff;
  & .MuiOutlinedInput-root {
    color: #474444;
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
    width: 100%;
  }
  & .MuiTypography-root,
  p {
    color: #fff;
  }
  & .MuiTypography-body1 {
    color: #fff;
  }
  & .MuiDivider-root,
  & .MuiFormControlLabel-root {
    & .MuiSvgIcon-root {
      color: #ebe784;
    }
  }

  & .MuiFormLabel-root {
    color: #fff;
  }
  & .MuiStepLabel-label {
    color: #fff;
  }
  & .MuiStepIcon-root {
    color: #fff;
  }

  & .Mui-active {
    color: #ebe784;
  }

  & .Mui-disabled {
    color: #b1b1b1;
  }

  & .Mui-completed {
    color: #7ffd3f;
  }
}
