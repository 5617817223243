.nav {
  background: transparent;
  padding: 20px 30px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  & .nav-links {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  & .nav-link {
    margin-left: 50px;
    padding: 5px;
  }

  & a {
    color: #c1c0c0;

    &.active {
      border-bottom: 2px solid #ffe784;
      padding-bottom: 3px;
      color: #ffe784;

      &:hover {
        color: #8a7c15;
      }
    }
  }

  & .login-link {
    border: 2px solid #c1c0c0;
    padding: 5px 25px;
    border-radius: 3px;
  }

  & .dashboard__menu {
    display: flex;
    align-items: center;
  }

  & .main__menu {
    display: flex;
    align-items: center;
  }

  .closed {
    display: none;
  }
}

.nav-dashboard-background {
  height: 190px;
  background: linear-gradient(to bottom right, #474444, #181819);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.nav-drawer-content-holder {
  margin-top: 50px;
}

.nav-drawer {
  width: 250px;
  height: 100%;
  background: linear-gradient(to bottom right, #474444, #181819);
}

.nav-drawer-item {
  color: #ebe784;
}
.nav-drawer-tools {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
}
.nav-drawer-tools-items {
  margin: auto;
}
.navbar-drawer-logo-header {
  text-align: center;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.nav-drawer-item-icons {
  color: rgba(226, 227, 228, 8);
}
