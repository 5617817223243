footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  //text-align: center;
  background: linear-gradient(to bottom right, #181819, #000);
  height: 20vh;
  display: flex;
  align-items: center;

  a {
    color: #ffe784;
  }

  & .contact_item {
    display: flex;
    align-items: center;
    //justify-content: center;

    & .contact_icon {
      color: #939393;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  & .footer {
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: left;
    height: 100%;
  }

  & h6 {
    font-size: 14px !important;
    text-transform: uppercase;
    margin-bottom: 10px !important;
    letter-spacing: 2px !important;
    color: #ccc;
  }

  & .news_date {
    color: #fff;
    font-size: 12px;
  }

  & .MuiTypography-paragraph {
    font-size: 11px;
    margin-bottom: 5px;
    color: #7c7c7c;
  }

  & .marginTop {
    margin-top: 33px;
  }

  & .MuiTypography-paragraph:last-of-type {
    color: #939393;
  }

  & .MuiMobileStepper-root {
    background-color: transparent;
    padding-left: 0;
    display: flex;
    justify-content: center;

    & .MuiMobileStepper-dot {
      background-color: rgb(83, 82, 82);
      margin-left: 0;
      width: 7px;
      height: 7px;
      margin-right: 10px;
    }

    & .MuiMobileStepper-dotActive {
      background-color: #eacd00;
      margin-left: 0;
      width: 7px;
      height: 7px;
      margin-right: 10px;
    }
  }

  .MuiGrid-grid-xs-4,
  .MuiGrid-grid-xs-3 {
    padding: 20px 60px;
  }

  /*  & .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #eacd00;
    padding: 0;
    min-width: 0;
    text-transform: capitalize;
    border-style: solid;
    border-color: red;
    &:hover {
      color: #8a7c15;
    }
  } */

  & .MuiGrid-grid-xs-2 {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    background: linear-gradient(to bottom right, #474444, #181819);

    & .news_icon_container {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
