.leaderboard_root {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background: transparent;
  justify-content: flex-end;
  align-items: center;
}
.leaderboard_notification {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;

  & .snackbar {
    background-color: rgba(0, 0, 0, 0.9);
    width: 350px;
    height: 100px;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    align-items: center;
  }

  & .notif_content {
    margin-left: 10px;
    & .notif_title {
      color: #ffe784;
      font-size: 24px;
    }

    & .notif_message {
      color: #fff;
      font-size: 20px;
    }
  }
}

.leaderboard {
  height: 100%;
  background-color: transparent;
  overflow: auto;
  aspect-ratio: 1098 / 1080;

  width: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  & .leaderboard_header_container {
    display: flex;
    justify-content: center;
    height: 15%;
    & .leaderboard_info_container {
      display: flex;
      text-align: center;
      justify-content: center;

      & .leaderboard_info {
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        font-weight: 700;

        border-radius: 10px;
        width: 300px;
        height: 50px;
      }
    }

    & .leaderboard_header {
      display: flex;
      justify-content: center;
    }

    & .logo_container {
      background: transparent linear-gradient(180deg, #686869 0%, #4e4e52 100%)
        0% 0% no-repeat padding-box;
      display: flex;
      width: 30%;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
      height: 45%;
      flex-direction: column;
      & .underline {
        border-bottom: solid;
        border-width: thin;
        width: 50%;
        border-color: #bbbbbb;
      }

      & .logo {
        height: 55%;
        object-fit: contain;
        padding-bottom: 4px;
        position: relative;
      }

      & .leaderboard_info {
        font-weight: bold;
        color: #bbbbbb;
        font-size: 14px;
      }
    }
  }

  & .grid-left {
    height: 65%;

    padding: 10px;
  }

  & .grid-right {
    height: 65%;
    & .grid-right-container {
      height: 100%;

      & .grid-right-1 {
        height: 60%;
        padding: 10px;
      }

      & .grid-right-2 {
        height: 60%;
        padding: 10px;
      }

      & .grid-right-3 {
        height: 40%;
        padding: 10px;
      }
    }
  }

  & .grid-bottom {
    height: 20%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  & .goal {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    color: #474444;
  }

  & .title {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    color: #474444;
  }

  & .placeholder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    & .placeholder-text {
      color: #fff;
      font-style: italic;
      font-size: 20px;
      font-weight: 700;
      opacity: 0.5;
    }
  }
}
.leaderboard::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.leaderboard__toppliste {
  z-index: -9;
  background: linear-gradient(to bottom right, #474444, #181819) !important;

  & .MuiGrid-grid-xs-4 {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 2;
  }

  & .MuiGrid-grid-xs-3 {
    position: relative;
    left: 20px;
    top: 20px;
    z-index: 2;
  }

  & .MuiSelect-icon {
    color: #fff;
    font-size: 37px;
    border-color: red;
    border-style: solid;
  }
}

/* .selectInput {
  color: #fff !important;
  // font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold !important;
} */

/* .MuiFormLabel-root {
  color: #fff;
} */

/* .MuiTableCell-root {
  border-bottom: none !important;
}
 */
// Table

/* .MuiTableBody-root {
  padding: 20px;
}
 */
.leaderboard_card {
  padding: 5px;
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(180deg, #313132 0%, #28282b 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
}

.alo {
  // position: relative;
  max-width: 100%;
  height: 100%;

  & .tableHead {
    // background: linear-gradient(121deg, #313132 0%, #000000 100%) 0% 0%
    // no-repeat padding-box;
    height: 60px;
  }

  & .user__body {
    padding: 0px 0;
    border-bottom: none;
    display: flex;

    width: 100%;

    & .user_content {
      width: 80%;
      display: flex;
      flex-direction: row;
    }

    & .user__body--nr {
      background-color: #161618;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 3px;
      width: 10%;
      text-align: center;
      font-weight: 600;
      color: #fff;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    & .user__body--bg-color {
      display: flex;
      background: #404040 0% 0% no-repeat padding-box;
      border-bottom-right-radius: 10px;
      height: 5vh;
      flex: 1;
      width: 90%;
      max-width: 90%;
    }

    & .user__body--profile {
      padding: 0px 23px 0px 11px;
      background-color: #66707b;
      clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
      display: flex;
      align-items: center;
      width: 20%;

      & .avatar {
        height: 3vh;
        width: 3vh;
        border-style: solid;
        border-width: 2px;
      }

      & span {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        //border-radius: 50%;
      }
    }

    & .user__div {
      width: 100%;
      display: flex;
      flex-direction: column;

      padding: 2px 10px;

      .user_bottom {
        display: flex;
        align-items: center;

        & .user_percentage {
          color: #fff;
          opacity: 0.7;
          font-size: 9px;
          margin-left: 5px;
        }
      }

      & .user__results {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;

        & .user__amount {
          color: #fff;
          opacity: 0.7;
          font-size: 11px;
        }

        & h6 {
          color: #fff;
          font-size: 13px;
          line-height: normal;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          /* &:last-of-type {
            font-weight: 300;
          } */
        }
      }

      & .line__style {
        height: 3px;
        background-color: #ffe784;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      & .progress-bar {
        width: 100%;
        height: 3px;
        background-color: #66707b;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }

    & .user_quantity_container {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & .user_quantity {
        color: #fff;
        font-weight: 600;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      & .quantity_suffix {
        font-size: 12px;
        line-height: 12px;
        color: #fff;
      }
    }
  }
  & .MuiTableCell-root {
    font-weight: 900;
  }

  & .quantity__body {
    // display: flex;
    // line-height: normal;

    & span {
      margin-left: 3px;
    }
  }
}
.greyCell {
  background: #52565a;
  margin-bottom: 12px;
}

.LeaderBoardTable {
  border-collapse: separate;
  border-spacing: 0px 4px;
  height: 100%;
  width: 100%;
}
