.register__page {
  padding: 100px 0 50px;

  & form {
    position: relative;
    border-radius: 10px;
    background: linear-gradient(to bottom right, #474444, #181819);
    width: 900px;
    display: flex;
    margin: 0 auto;

    & .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
      color: rgba(0, 0, 0, 0.6) !important;
      font-size: 1rem !important;
      text-transform: none;
      font-weight: 400 !important;
    }

    & .MuiGrid-grid-xs-7 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 70px;

      & button {
        margin-top: 20px;
      }

      & .login--header {
        text-align: center;
        margin-bottom: 15px;

        & p {
          text-align: center;
          color: #fff;
          font-size: 13px;
        }
      }

      & .css-sghohy-MuiButtonBase-root-MuiButton-root {
        padding: 13px 80px;
        background-color: #3ab19b;
        border-radius: 30px;
        margin-top: 20px;
      }

      & .MuiOutlinedInput-root {
        color: #474444;
        background: linear-gradient(to bottom right, #fff, #f7f5c7);
      }
    }

    & .css-o2w69a-MuiTypography-root {
      font-size: 50px;
      font-weight: 600;
      color: #ebe784;
    }

    & .logo {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 80px;
    }

    & .login--social__media {
      text-align: center;
      margin: 10px 0;

      & svg {
        color: #000;
      }
    }

    & .forgot--pass {
      text-decoration: underline;
      margin-bottom: 10px;
      & a {
        color: #fff;
      }
    }

    & .MuiGrid-grid-xs-5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0px 10px;
      background-color: #ebe784;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      & h2 {
        font-size: 45px;
        font-weight: 500;
        color: #474444;
      }

      & p {
        color: #474444;
        padding: 20px 40px;
        font-size: 15px;
      }

      & button {
        padding: 13px 80px;
        background-color: #ebe784;
        border-radius: 30px;
        border: 2px solid #474444;
        box-shadow: none;
        color: #474444;

        & a {
          color: #fff;
        }
      }
    }
  }
}
