$font-family: 'Poppins', sans-serif;

// VARIABLES
$primary-color: #f45500;
$secondary-color: #052746;

// UTILS

@import './partials/layout';
@import './partials/header';
@import './partials/nav';
@import './partials/homePage';
@import './partials/loginRegister';
@import './partials/dashboardPage';
@import './partials/leaderboard';
@import './partials/footer';
@import './partials/register.scss';
@import './partials/main.scss';
@import './partials/profile.scss';
@import './partials/admin.scss';
@import './partials/charts.scss';
@import './partials/meme.scss';
@import './partials/resetPassword.scss';

.global-loader-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.global-loader {
  color: #fff;
}
