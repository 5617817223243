.reset_password_container {
  padding: 10px 0 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 384px;
  max-width: 100%;
  min-height: 480px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: linear-gradient(to bottom right, #474444, #181819);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & .reset_password_card {
    & .MuiOutlinedInput-root {
      color: #474444;
      background-color: #fff;
    }
  }
  & .logo {
    width: 75%;
    margin: auto;
  }
}
